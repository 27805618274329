<template>
  <WcBottomModal @backClick="backClick" hide-header>
    <div class="cloth-filter-modal">
      <div class="modal-nav">
        <a href="#" @click.prevent="back" class="modal-nav-icon">
          <img :src="require('@/assets/pwa-icons/chevron-left.png')" />
        </a>
        <a href="#" @click.prevent="confirmClicked">確認</a>
      </div>

      <div class="modal-main-content">
        <section class="filter-section">
          <h4 class="wc">搜尋</h4>

          <div class="tag-filter">
            <div class="search-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.5196 18.997C14.6726 18.997 18.0392 15.6398 18.0392 11.4985C18.0392 7.3572 14.6726 4 10.5196 4C6.36665 4 3 7.3572 3 11.4985C3 15.6398 6.36665 18.997 10.5196 18.997Z" stroke="#8D8D8D" stroke-width="1.8" stroke-miterlimit="10"/>
                <path d="M17.0996 16.1826L20.856 18.9903" stroke="#8D8D8D" stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="search-input">
              <InputTag v-model="inputTags"></InputTag>
            </div>
            <div class="search-clear" v-if="inputTags.length > 0" @click="clearInputTags">
              <p>清除全部</p>
            </div>
          </div>

          <div class="position-relative">
            <h4 class="wc">篩選</h4>
            <p class="clear-filter-btn" @click="clearFilter" v-if="inputFilterPublic !== -1 || inputFilterColors.length > 0">清除條件</p>
          </div>

          <div class="color-filters">
            <div class="colors">
              <ul>
                <li v-for="(item) in clothColors" :key="item.id">
                  <div class="" @click="toggleSelectColor(item.id)">
                    <ColorCircle :id="item.id" :active="inputFilterColors.indexOf(item.id) !== -1"></ColorCircle>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          
          <div class="publicity-filters">
            <button :class="{'selected': inputFilterPublic === 1}" @click="toggleFilterPublic(1)">
              <span v-if="inputFilterPublic === 1">✓</span>
              公開單品
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M10.0007 3.83341C8.62755 3.83341 7.50065 4.96032 7.50065 6.33342V8.83342H15.0007C15.9211 8.83342 16.6673 9.57961 16.6673 10.5001V17.1667C16.6673 18.0872 15.9211 18.8334 15.0007 18.8334H5.00065C4.08018 18.8334 3.33398 18.0872 3.33398 17.1667V10.5001C3.33398 9.57961 4.08018 8.83342 5.00065 8.83342H5.83398V6.33342C5.83398 4.03984 7.70708 2.16675 10.0007 2.16675C12.2942 2.16675 14.1673 4.03984 14.1673 6.33342C14.1673 6.79365 13.7942 7.16675 13.334 7.16675C12.8737 7.16675 12.5007 6.79365 12.5007 6.33342C12.5007 4.96032 11.3737 3.83341 10.0007 3.83341ZM5.00065 10.5001V17.1667H15.0007V10.5001H5.00065Z" fill="#8D8D8D"/>
              </svg>
            </button>

            <button :class="{'selected': inputFilterPublic === 0}" @click="toggleFilterPublic(0)">
              <span v-if="inputFilterPublic === 0">✓</span>
              私人單品
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M10.0007 3.83341C11.3737 3.83341 12.5007 4.96032 12.5007 6.33342V8.83342H7.50065V6.33342C7.50065 4.96032 8.62755 3.83341 10.0007 3.83341ZM14.1673 8.83342V6.33342C14.1673 4.03984 12.2942 2.16675 10.0007 2.16675C7.70708 2.16675 5.83398 4.03984 5.83398 6.33342V8.83342H5.00065C4.08018 8.83342 3.33398 9.57961 3.33398 10.5001V17.1667C3.33398 18.0872 4.08018 18.8334 5.00065 18.8334H15.0007C15.9211 18.8334 16.6673 18.0872 16.6673 17.1667V10.5001C16.6673 9.57961 15.9211 8.83342 15.0007 8.83342H14.1673ZM5.00065 10.5001H15.0007V17.1667H5.00065V10.5001Z" fill="#8D8D8D"/>
              </svg>
            </button>
          </div>
        </section>

        <section>
          <h4 class="wc">顯示設定</h4>
          <div class="row no-gutters justify-content-between mb-3 pl-2 pr-3">
            <div class="col-auto" style="color: var(--text-color-remark)">顯示排序細項</div>
            <div class="col-auto">
              <WcSwitch v-model="inputShowBlockText"></WcSwitch>
            </div>
          </div>
        </section>

        <section class="sort-section">
          <h4 class="wc">排序方式</h4>
          <ul>
            <li v-for="(item, index) in sortRules" :key="index">
              <div class="row no-gutters align-items-center">
                <div class="sort-check-div">
                  <img :src="require('@/assets/pwa-icons/check.png')" v-if="item.value === inputSortType"/>
                </div>
                <div class="sort-name">
                  <p class="wc mb-0">
                    {{ item.name }}
                  </p>
                </div>
                <div class="sort-btns">
                  <button class="btn sort-btn mr-2" :class="{'selected': item.value === inputSortType && !inputSortDirectionDesc}" @click="setSort(item.value, false)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M16 10L12 6M12 6L8 10M12 6L12 18" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>

                  <button class="btn sort-btn" :class="{'selected': item.value === inputSortType && inputSortDirectionDesc}" @click="setSort(item.value, true)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8 14L12 18M12 18L16 14M12 18L12 6" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </WcBottomModal>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import InputTag from 'vue-input-tag';
import ColorCircle from '@/components/closet/ColorCircle.vue';
import WcBottomModal from '@/components/commons/WcBottomModal.vue';
import WcSwitch from '@/components/commons/WcSwitch.vue';

export default {
  name: 'ClothFilterModal',
  data() {
    return {
      inputTags: [],
      inputFilterColors: [],
      inputFilterPublic: -1,      
      
      inputSortType: '',
      inputSortDirectionDesc: false,

      sortRules: [
        {
          name: '依單品分類（預設）',
          value: 'category',
        },
        {
          name: '依單品價格',
          value: 'price',
        },
        {
          name: '依單品入手時間',
          value: 'getTime',
        },
        {
          name: '依新增單品時間',
          value: 'createTime',
        },
        {
          name: '依穿著次數',
          value: 'wearCount',
        },
      ],

      inputShowBlockText: true,
    };
  },
  created() {
    this.inputTags.splice(0, this.inputTags.length);
    for (const c of this.filterTags) {
      this.inputTags.push(c);
    }
    this.inputFilterColors.splice(0, this.inputFilterColors.length);
    for (const c of this.filterColors) {
      this.inputFilterColors.push(c);
    }
    this.inputSortType = this.sortType;
    this.inputSortDirectionDesc = this.sortDirectionDesc;
    this.inputFilterPublic = this.filterPublic;
    this.inputShowBlockText = this.showBlockText;
  },
  props: {
    filterTags: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    filterColors: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    filterPublic: {
      type: Number,
      required: false,
      default: -1,
    },
    sortType: {
      type: String,
      required: false,
      default: 'category',
    },
    sortDirectionDesc: {
      type: Boolean,
      require: false,
      default: false,
    },
    showBlockText: {
      type: Boolean,
      require: false,
      default: true,
    }
  },
  components: {
    WcBottomModal,
    ColorCircle,
    InputTag,
    WcSwitch,
  },
  computed: {
    ...mapState(['clothColors']),
    confirmObject() {
      return {
        filterTags: this.inputTags,
        filterColors: this.inputFilterColors,
        filterPublic: this.inputFilterPublic,
        sortType: this.inputSortType,
        sortDesc: this.inputSortDirectionDesc,
        showBlockText: this.inputShowBlockText,
      };
    },
	},
  watch: {
    
  },
  async mounted() {
    
	},
  methods: {
    back() {
      this.$emit('back');
    },
    backClick() {
      this.$emit('backClick');
    },
    toggleSelectColor(colorId) {
      const index = this.inputFilterColors.indexOf(colorId);
      if (index === -1) {
        this.inputFilterColors.push(colorId);
      } else {
        this.inputFilterColors.splice(index, 1);
      }
    },
    toggleFilterPublic(pub) {
      if (this.inputFilterPublic === pub) {
        this.inputFilterPublic = -1;
      } else {
        this.inputFilterPublic = pub;
      }
    },
    confirmClicked() {
      this.$emit('confirm', this.confirmObject);
    },
    setSort(type, isDesc) {
      this.inputSortType = type;
      this.inputSortDirectionDesc = isDesc;
    },
    clearInputTags() {
      this.inputTags.splice(0, this.inputTags.length);
    },
    clearFilter() {
      this.inputFilterColors.splice(0, this.inputFilterColors.length);
      this.inputFilterPublic = -1;
    },
    tagChanged(tags) {
      console.log(tags);
    },
  }
}
</script>

<style lang="scss" scoped>
  .cloth-filter-modal {
    padding: 0 .8rem;
  }

  .modal-main-content {
    max-height: 75dvh;
    max-height: 75vh;
    overflow-y: auto;
  }

  .clear-filter-btn {
    color: var(--text-color-main);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .filter-section {
    margin-bottom: 1.3rem;

    .tag-filter {
      border-radius: 0.5rem;
      background: #EEE;
      padding: 0.25rem .75rem;

      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .search-icon {
        flex: 0 0 auto;
      }

      .search-input {
        flex: 1 1;
        ::v-deep .vue-input-tag-wrapper {
          background-color: transparent;
          border: none;
          .input-tag {
            color: #fff;
            border: none;
            padding: 0.25rem 0.75rem 0.25rem 1rem;
            border-radius: 999px;
            background: var(--icon-color-selection);
            .remove {
              color: #fff;
            }
          }
        }
      }

      .search-clear {
        flex: 0 0 auto;
        p {
          margin: 0;
          color: var(--text-color-remark);
        }
      }
    }

    .color-filters {
      width: 100%;
      overflow-x: auto;
      margin-bottom: .25rem;
      padding-bottom: .25rem;
      .colors {
        width: fit-content;
        padding: .25rem;
        >ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          padding: 0;
          margin: 0;
          list-style: none;
          ::v-deep >li {
            flex: 0 0 auto;
            padding: 0;
            margin: 0;
            .ball.active .color::after {
              content: '✓';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
            &:nth-child(1) .ball.active .color::after {
              color: #000;
            }
          }
          
        }
      }
    }
    .publicity-filters {
      display: flex;
      align-items: center;
      column-gap: .75rem;
      margin-top: .5rem;
      button {
        background-color: #fff;
        border: solid 1px var(--icon-color-option);
        border-radius: 999px;
        color: var(--icon-color-option);
        display: flex;
        align-items: center;
        padding: .38rem 1rem;
        column-gap: .25rem;
        &.selected {
          border: 1px solid var(--icon-color-selection);
          color: var(--icon-color-selection);
          // background-color: var(--icon-color-option);
          // color: #fff;
          svg {
            path {
              fill: var(--icon-color-selection);
            }
          }
        }
      }
    }
  }

  .modal-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    a {
      font-size: 1rem;
      font-weight: 1000;
    }
  }

  .modal-nav-icon {
    display: inline-block;
  }

  .modal-nav-icon>img {
    width: 24px;
    height: 24px;
  }

  .sort-section ul, .sort-section ul li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .sort-section ul li {
    padding: .5rem 0;
    border-bottom: 1px solid var(--border-color-main, #D9D9D9);
  }

  .sort-check-div {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }

  .sort-check-div>img {
    display: block;
    width: 24px;
    height: 24px;
  }

  .sort-name {
    flex: 1 1;
    padding: 0 1rem;
  }

  .sort-btns {
    flex: 0 0 auto;
  }

  .btn.sort-btn {
    padding: 0.375rem;
    border-radius: 0.5rem;
    border: 1px solid var(--border-color-main, #D9D9D9);
  }

  .btn.sort-btn path {
    stroke: #8D8D8D;
  }

  .btn.sort-btn.selected {
    border-radius: 0.5rem;
    border: 1px solid var(--text-color-remark, #8D8D8D);
    background: var(--text-color-remark, #8D8D8D);
  }

  .btn.sort-btn.selected path {
    stroke: #fff;
  }
</style>
