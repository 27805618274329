<template>
  <div>
    <ul class="list-ul">
      <li v-for="(item, index) in items" :key="index" @click="selectingIndex = index">
        <div class="check-circle" :class="{'active': selectingIndex === index}"></div>
        <p class="check-title">{{ item }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VerticalList',
  data() {
    return {
      selectingIndex: -1,
    }
  },
  components: {
  },
  created() {
    this.selectingIndex = this.value;
  },
  mounted() {
    
  },
  watch: {
    selectingIndex(v) {
      this.$emit('input', v);
    }
  },
  computed: {
    
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  ul.list-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: .8rem;
      padding: 1rem 0;
      &:not(:first-child) {
        border-top: solid 1px #D9D9D9;
      }
      .check-circle {
        position: relative;
        flex: 0 0 auto;
        width: 1.2rem;
        height: 1.2rem;
        border: solid 1px var(--border-color-main);
        border-radius: 50%;
        padding: .2rem;
        &.active::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: var(--brand-color-primary);
        }
      }

      .check-title {
        flex: 1 1;
        margin: 0;
      }
    }
  }
</style>
