<template>
  <div class="cloth-card-list">
    <div class="row no-gutters">
      <div class="col-4 mb-2 px-1" v-for="(item) in sortedCloths" :key="item.serial">
        <div @click="clothBlockClicked(item)" class="no-touch" :class="{'half-transparent': isSelectMode, 'selected': findClothInSelectedArray(item)}">
          <ClothCardBlock :cloth="item" :showTitle="showTitle" :showTitleType="showTitleType" :mode="mode" :selected="findClothInSelectedArray(item)">
          </ClothCardBlock>
        </div>
      </div>
    </div>

    <div class="cloth-empty pt-4" v-if="sortedCloths.length === 0">
      <p class="text-center">沒有符合的單品</p>
    </div>
  </div>
</template>

<script>
import ClothCardBlock from '@/components/clothList/ClothCardBlock.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ClothCardList',
  data() {
    return {
      
    }
  },
  props: {
    cloths: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    sortType: {
      type: String,
      required: false,
      default: 'category',
    },
    sortDirectionDesc: {
      type: Boolean,
      require: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'view',
    },
    selectedIds: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  
  },
  components: {
    ClothCardBlock,
  },
  created() {
  },
  computed: {
    ...mapState(['clothCategories']),
    filteredCloths() {
      let fs = [... this.cloths];
      fs = fs.filter((c) => {
        for (const f of this.filters) {
          if (f.type === 'tags') {
            let found = false;
            for (const tag of c.brands) {
              if (f.value.indexOf(tag) !== -1) {
                found = true;
                break;
              }
            }

            if (!found) {
              for (const tag of c.tags) {
                if (tag.indexOf(f.value) !== -1) {
                  found = true;
                  break;
                }
              }
              const c1Name = this.__queryCategoryName(c.category1);
              const c2Name = this.__queryCategoryName(c.category2);
              if (c1Name.indexOf(f.value) !== -1 || c2Name.indexOf(f.value) !== -1 ) {
                found = true;
              }
              // console.log(`${c1Name}, ${c2Name}, ${found}`);
            }
            if (!found) {
              return false;
            }
          } else if (Array.isArray(c[f.type])) {
            if (Array.isArray(f.value)) {
              let found = false;
              for (const tag of c[f.type]) {
                if (f.value.indexOf(tag) !== -1) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                return false;
              }
            } else {
              if (c[f.type].indexOf(f.value) === -1) {
                return false;
              }
            }
          } else {
            if (Array.isArray(f.value)) {
              if (f.value.indexOf(c[f.type]) === -1) {
                return false;
              }
            } else {
              if (c[f.type] !== f.value) {
                return false;
              }
            }
          }
        }
        
        return true;
      });
      return fs;
    },
    sortedCloths() {
      let list = [... this.filteredCloths];
      const desc = this.sortDirectionDesc?1:-1;
      switch (this.sortType) {
        case 'category':
          list = list.sort((a, b) => {
            if (a.category1 === b.category1) {
              return desc * (a.category2 - b.category2);
            } else {
              return desc * (a.category1 - b.category1);
            }
          });
          break;
        case 'price': 
          list = list.sort((a, b) => {
            return desc * (a.price - b.price);
          });
          break;
        case 'getTime':
          list = list.sort((a, b) => {
            if (a.getYear === b.getYear) {
              return desc * (a.getMonth - b.getMonth);
            } else {
              return desc * (a.getYear - b.getYear);
            }
          });
          break;
        case 'createTime':
          list = list.sort((a, b) => {
            return desc * (new Date(a.create_at).getTime() - new Date(b.create_at).getTime());
          });
          break;
        case 'wearCount':
          list = list.sort((a, b) => {
            return desc * (a.wearCount - b.wearCount);
          });
          break;
      }
      return list;
    },
    showTitleType() {
      switch (this.sortType) {
        case 'category':
          return 'category';
        case 'price': 
          return 'price';
        case 'getTime':
          return 'getTime';
        case 'createTime':
          return 'createTime';
        case 'wearCount':
          return 'wearCount';
      }
      return 'category';
    },
    isSelectMode() {
      return this.mode === 'select';
    },
  },
  mounted() {
  },
  watch: {
    
  },
  methods: {
    ...mapActions(['queryCategoryName']),
    __queryCategoryName(categoryId) {
      const cs = this.clothCategories;
      for (const c of cs) {
        if (c.serial === categoryId) {
          return c.name;
        }
        for (const s of c.sub) {
          if (s.serial === categoryId) {
            return s.name;
          }
        }
      }
      return '';
    },
    findClothInSelectedArray(cloth) {
      if (this.selectedIds.indexOf(cloth.serial) !== -1) {
        return true;
      }
    },
    clothBlockClicked(cloth) {
      this.$emit('clothClicked', cloth);
    },
  }

}
</script>

<style lang="scss" scoped>
  .cloth-card-list {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .top-tabs {
    flex: 0 0 auto;
  }

  .bottom-list {
    flex: 1 1;
    overflow-y: auto;
  }

  .cloth-empty {
    font-size: 1.2rem;
    margin: 0;
  }

  .half-transparent:not(.selected) {
    opacity: .5;
  }

  .no-touch {
    touch-action: none;
    touch-action: pan-y;
  }
</style>
